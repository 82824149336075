import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { feedbackHelpful, logUserAction } from "../apiCall";
import FeedbackPopup from "./FeedbackPopup";

const WasHelpFull = (props) => {
  const [showReasonPopup, setShowReasonPopup] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [disableButton, setDisableButton] = useState("");

  const setFeedBack = (positiveSelected, feedbackButton) => {
    setSelectedFeedback(positiveSelected);
    setShowReasonPopup(true);
    setDisableButton(feedbackButton);
  };

  const handleReasonSubmit = async (reason) => {
    let query;
    if (props.docDetail.authdoc) {
      query = {
        action: "feedback",
        documentId: JSON.parse(props.docDetail.authorData).id,
        title: JSON.parse(props.docDetail.authorData).procedureTitle[0],
        params: {
          id: JSON.parse(props.docDetail.authorData).id,
          positive: selectedFeedback,
          reason,
        },
      };
    } else {
      query = {
        action: "feedback",
        documentId: props.docDetail.id,
        title: props.docDetail.file_link,
        params: { id: props.docDetail.id, positive: selectedFeedback, reason },
      };
    }

    logUserAction({
      documentId: props.docDetail.authdoc
        ? JSON.parse(props.docDetail.authorData).id
        : props.docDetail.id,
      route: "successfeedback",
      successfeedback: selectedFeedback,
    });

    await feedbackHelpful(query).then(() => {
      logUserAction({
        documentId: props.docDetail.authdoc
          ? JSON.parse(props.docDetail.authorData).id
          : props.docDetail.id,
        route: "closedocument",
      });
      setShowReasonPopup(false);
      setShowThankYouPopup(true);
    });
  };

  const backButtonClick = () => {
    logUserAction({
      documentId: props.docDetail.authdoc
        ? JSON.parse(props.docDetail.authorData).id
        : props.docDetail.id,
      route: "closedocument",
    });
  };

  return (
    <>
      <div className="pl-7 pr-3 pt-8 pb-5 border-t border-gray-300 bg-[#FAFAFA] flex align-middle">
        <Link to={props.newPath} onClick={backButtonClick}>
          <button className="w-20 h-9 mr-1 ml-auto bg-white text-blue-600 border-blue-600 border-2 rounded-md">
            <span className="material-symbols-outlined text-xs font-extrabold">
              arrow_back_ios
            </span>
            Back
          </button>
        </Link>

        <div className="ml-[55%] flex align-middle">
          <div
            className="text-[10px] md:text-sm text-gray-500 pt-2 pr-0 md:pr-5 italic leading-[10px] md:leading-[24px]"
            style={{ opacity: "0.5" }}
          >
            Was this result helpful for you?
          </div>
          <button
            onClick={() => setFeedBack(true, "Yes")}
            className={`w-20 h-9 mr-1 ml-auto ${
              disableButton === "Yes" ? `bg-gray-500` : `bg-white`
            } text-blue-600 border-blue-600 border-2 rounded-md`}
            disabled={disableButton === "Yes"}
          >
            Yes
          </button>
          <button
            onClick={() => setFeedBack(false, "No")}
            className={`w-20 h-9 mr-1 ml-auto ${
              disableButton === "No" ? `bg-gray-500` : `bg-white`
            } text-blue-600 border-blue-600 border-2 rounded-md`}
            disabled={disableButton === "No"}
          >
            No
          </button>
        </div>

        {showReasonPopup && (
          <FeedbackPopup
            isOpen={showReasonPopup}
            onClose={() => setShowReasonPopup(false)}
            onSubmit={handleReasonSubmit}
          />
        )}

        {showThankYouPopup && (
          <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-filter backdrop-blur-sm">
            <div className="bg-white rounded-lg p-8 max-w-md mx-auto border border-gray-400">
              <h2 className="text-2xl mb-4">Thank You!</h2>
              <p className="text-gray-700">
                Your Feedback has been successfully submitted.
              </p>
              <button
                className="mt-4 ml-[18.5rem] px-4 py-2 border border-[#034E91] text-[#034E91] bg-[#ffffff] hover:bg-[#d2ebfa] text-black rounded"
                onClick={() => setShowThankYouPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    newPath: state.newPath,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps)(WasHelpFull);
