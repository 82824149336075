import React, { useState } from 'react';
import { Modal, Button, TextArea, Form, Icon } from 'semantic-ui-react';

const FeedbackPopup = ({ isOpen, onClose, onSubmit }) => {
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    onSubmit(feedback);
    setSubmitted(true);
    setFeedback('');
  };

  const handleClose = () => {
    setSubmitted(false);
    setFeedback('');
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose} size='small'>
      {!submitted ? (
        <>
          <Modal.Header>Please provide your Feedback</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <TextArea
                  placeholder='Enter your Feedback here...'
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  rows="4"
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleClose} style={{ backgroundColor: '#0971CE', color: 'white' }}>
              Cancel
            </Button>
            <Button
              positive
              onClick={handleSubmit}
              style={{ backgroundColor: '#0971CE', color: 'white' }}
              disabled={feedback===''? true : false} 
            >
              Submit
            </Button>
          </Modal.Actions>
        </>
      ) : (
        <>
          <Modal.Content style={{ textAlign: 'center' }}>
            <Icon name='check circle' size='big' color='green' />
            <h3>Submitted successfully</h3>
          </Modal.Content>
          <Modal.Actions style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleClose} style={{ backgroundColor: '#0971CE', color: 'white' }}>
              OK
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

export default FeedbackPopup;
